<script>
    import { onMount } from "svelte";
    import { fetchData } from "../base";
    import PageHeading from "../component/header/PageHeading.svelte";
    import FullLoading from "../component/Layout/FullLoading.svelte";
    import { pageStore } from "../store/pageDataStore";
    import { getImagePath } from "../filePath";
    import { enabledAdminView } from "../store/adminStore";
    import AdminEditButton from "../component/element/AdminEditButton.svelte";
    import { getLocalizedText } from "senselogic-gist";
    import { textStore } from "../store/textStore";
    import { languageTagStore } from "../store/languageTagStore";

    let isLoading = true;
    let faqPage = $pageStore.page.faq;
    let faqBlockArray = $pageStore.block.faq;

    async function loadData(
        )
    {
        try
        {
            isLoading = true;

            let faqPageData =
                await fetchData(
                            '/api/page/get-by-route',
                            {
                                method: 'POST',
                                body: JSON.stringify(
                                    {
                                        route: '/faq'
                                    }
                                ),
                                headers: { 'Content-Type': 'application/json' }
                            }
                        );

            let faqPageBlockArray =
                await fetchData(
                            '/api/block/get-by-page-id',
                            {
                                method: 'POST',
                                body: JSON.stringify(
                                    {
                                        pageId: 'WGdOsp_M5SSBwpLvSqS7ww'
                                    }
                                ),
                                headers: { 'Content-Type': 'application/json' }
                            }
                        );

            faqPage = faqPageData.page;
            faqBlockArray = faqPageBlockArray.blockArray;

            $pageStore.page.faq = faqPageData.page;
            $pageStore.block.faq = faqPageBlockArray.blockArray;
        }
        catch ( error )
        {
            console.error( 'Error:', error )
        }
        finally
        {
            isLoading = false;
        }
    }

    onMount(
        () =>
        {
            if (
                  faqPage === undefined
                  || faqBlockArray === undefined
            )
            {
                loadData()
            }
            else
            {
                isLoading = false;
            }
        }
    );

</script>

<style lang="stylus">
    // -- IMPORTS

    @import '../../constant.styl';
    @import '../../mixin.styl';

    // -- CLASSES

    .faq-page-heading
    {
        width: 60%;

        position: relative;
    }

    .faq-heading-image
    {
       height: 27.5rem;
       width: 100%;
       aspect-ratio: 3 / 4;

       object-fit: cover;

       +media( desktop )
       {
           height: 37.5rem;
       }
    }

    .faq-section
    {
        margin-top: 10rem;
        padding: 0rem 1.5rem 5rem 1.5rem;

        display: flex;
        flex-direction: column;
        gap: 5.5rem;

        +media( desktop )
        {
            max-width: 62.5vw;
        }
    }

    .faq-topic-container
    {
        display: flex;
        flex-direction: column;
        gap: 2.5rem;
        align-items: left;
    }

    .faq-question-container
    {
        display: flex;
        flex-direction: column;
    }

    .faq-topic-title
    {
        margin-bottom: 1rem;

        line-height: 3rem;
        font-size: 2rem;
        font-weight: 200;
        font-style: normal;
        color: lightGreyColor;

        +media( desktop )
        {
            margin-bottom: 2rem;

            line-height: 5rem;
            font-size: 4rem;
        }
    }

    .faq-question-text
    {
        color: lightGoldColor;
        leading-trim: both;
        text-edge: cap;
        line-height: 3rem;
        font-size: 1.5rem;
        font-weight: 600;
        font-style: normal;

        +media( desktop )
        {
            line-height: 3rem;
            font-size: 2rem;
        }
    }

    .faq-answer-text
    {
        line-height: 1.5rem;
        font-size: 1rem;
        font-weight: 400;
        font-style: normal;
        color: lightGreyColor;

        +media( desktop )
        {
            line-height: 2.5rem;
            font-size: 1.5rem;
        }
    }

    .horizontal-line
    {
        margin-top: 2rem;
        height: 1.5px;
        width: 100%;

        background-color: #2a2a2a;

        +media( desktop )
        {
            margin-top: 3rem;
        }
    }

    .require-service-text
    {
        margin-bottom: 3rem;

        +media( desktop )
        {
            margin-bottom: 5rem;
        }
    }
</style>

{#if isLoading }
    <FullLoading />
{:else}
    <div class="faq-page-heading">
        {#if faqPage.id }
            <AdminEditButton
                inset="0 5rem auto auto"
                type="page"
                id={ faqPage.id }
            />
        {/if}
    </div>

    {#if faqPage.title }
        <PageHeading
            title={ getLocalizedText( faqPage.title || '', $languageTagStore ) }
        />
    {/if}


    {#if faqPage.imagePath }
        <div
            class="faq-heading-image"
            style="
                background: URL( { getImagePath( faqPage.imagePath, '1920' ) } ) no-repeat center center / cover,
                            URL( { getImagePath( faqPage.imagePath, '360' ) } ) no-repeat center center / cover;
                "
        />
    {/if}

    <div class="faq-section" class:is-admin-enabled={ $enabledAdminView }>
        {#each faqBlockArray as topic }
            {#if topic.typeSlug === 'list' }
                <div class="faq-topic-container" class:is-admin-enabled={ $enabledAdminView }>
                    <span class="faq-topic-title">{ getLocalizedText( topic.title || '', $languageTagStore ) }</span>

                    {#each topic.textArray as question }
                        <div class="faq-question-container">
                            <p class="faq-question-text">{ getLocalizedText( question.title || '', $languageTagStore ) }</p>

                            <span class="faq-answer-text">{ getLocalizedText( question.text || '', $languageTagStore ) }</span>
                        </div>
                    {/each}

                    <AdminEditButton
                        type="block"
                        id={ topic.id }
                    />

                    <hr class="horizontal-line" />
                </div>
            {/if}
        {/each}

        <p class="require-service-text">{ getLocalizedText( $textStore[ 'require-service-label' ] || '', $languageTagStore ) }</p>
    </div>
{/if}
