<script>
    // -- IMPORTS

    import { getImagePath } from '$lib/filePath';
    import { getLocalizedText } from 'senselogic-gist';
    import { languageTagStore } from '$lib/store/languageTagStore';
  import AdminEditButton from '../element/AdminEditButton.svelte';
  import { enabledAdminView } from '$src/lib/store/adminStore';

    // -- VARIABLES

    export let imagePath;
    export let text;
    export let id;

    let textElement;

    function handleExpandText(
        )
    {
        textElement.style.webkitLineClamp = 'unset';
        textElement.style.maxHeight = '100rem';
    };

    function handleCollapseText(
        )
    {
        textElement.style.maxHeight = '6rem';

        setTimeout(() => {
            textElement.style.webkitLineClamp = '4';
        }, 350);
    };
</script>

<style lang="stylus">
    // -- IMPORTS

    @import '../../../constant.styl';
    @import '../../../mixin.styl';

    // -- CLASSES

    .partner-companies-card
    {
        width: 100%;
        padding: 0px 1.25rem;

        display: flex;
        flex: 1;
        flex-direction: column;
        flex-shrink: 0;
        gap: 1rem;
        align-items: center;

        transition: all 2s;
    }

    .partner-companies-card-icon-container
    {
        height: 4.5rem;
        width: 16rem;

        display: flex;
        flex-direction: column;
        gap: 0.625rem;
        justify-content: center;
        align-items: center;
    }

    .partner-companies-card-text
    {
        position: relative;

        overflow: hidden;

        text-align: center;
        color: grayColor400;
        leading-trim: both;
        text-edge: cap;
        line-height: 1.5;
        font-size: 1rem;
        text-overflow: ellipsis;
        -webkit-line-clamp: 4;

        width: 100%;

        display: -webkit-box;
        -webkit-box-orient: vertical;
        max-height: 6rem;

        transition: all .3s;
    }

    .hover-expand
    {
        max-height: 100rem;
    }

    .partner-companies-card-icon
    {
        margin: 0 auto;
        height: 100%;
        width: 100%;

        flex: 1 1 1;
        align-self: stretch;
        object-fit: contain;

        filter: grayscale( 1 );
    }
</style>

<div class="partner-companies-card" class:is-admin-enabled={ $enabledAdminView }>
    <AdminEditButton
        type="partner-company"
        id={ id }
    />

    <div class="partner-companies-card-icon-container">
        {#if imagePath }
            <div
                class="partner-companies-card-icon"
                style="
                    background: URL( { getImagePath( imagePath, '1920' ) } ) no-repeat center center / contain,
                                URL( { getImagePath( imagePath, '360' ) } ) no-repeat center center / contain;
                    "
            />
        {/if}
    </div>

    <div
        class="partner-companies-card-text"
        title={ getLocalizedText( text, $languageTagStore ) }
        bind:this={ textElement }
        on:mouseenter={ handleExpandText }
        on:mouseleave={ handleCollapseText }
    >
        {#if text }
            { getLocalizedText( text, $languageTagStore ) }
        {/if}
    </div>
</div>
