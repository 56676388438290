<script>
    // -- IMPORTS

    import MarkerCard from './MarkerCard.svelte';

    // -- VARIABLES

    export let markerIndex = 0;
    export let marker;

    let isZoomedIn = window.visualViewport.scale > 1;
    let isMarkerCardOpen = false

    // -- FUNCTIONS

    function handleOpenMarkerCard(
        event
        )
    {
        let existingOpenCard = document.querySelector('.is-hover');

        if ( existingOpenCard 
             && existingOpenCard.id !== event.target.id
        ) 
        {
            existingOpenCard.classList.remove('is-hover')
        }

        isMarkerCardOpen = true;
    }

    // ~~

    function handleCloseMarkerCard(
        event
        )
    {
        if ( screen.width > 1064 )
        {
            isMarkerCardOpen = false;
        }

        if ( 
            isMarkerCardOpen == true
            && event.target.closest( ":not(.marker-card)" ) 
            && !event.target.closest('.marker-container') )
        {
            isMarkerCardOpen = false;
        }
    }

     // ~~

    function handleResize() 
    {
       isZoomedIn = window.visualViewport.scale > 1;
    }

    // -- STATEMENTS

    window.visualViewport.addEventListener( "resize", handleResize );

    $: if ( isZoomedIn === false )
       {
            window.addEventListener( "touchstart", handleCloseMarkerCard );
       }
       else
       {
            window.removeEventListener( "touchstart", handleCloseMarkerCard );
       }
</script>

<style lang="stylus">
    // -- IMPORTS

    @import '../../../constant.styl';
    @import '../../../mixin.styl';

    // -- CLASSES

    .marker-container
    {
        position: absolute;
        transform: translate( -50%, -100% );
        z-index: 2;

        display: flex;
        flex-direction: column;
        flex-shrink: 0;
        gap: -40px;
        justify-content: flex-end;
        align-items: center;

        font-size: 1rem;

        cursor: pointer;
        transition: transform 400ms ease-in-out, opacity 500ms ease-in-out, visibility 400ms ease-in-out;
    }

    .marker-content
    {
        transform: rotate(-45deg);

        border-radius: 16px;
        padding: 1px;

        display: flex;
        gap: 8px;
        justify-content: center;
        align-items: center;

        background: rgba( 173, 166, 144, 0.00 );

        transition: padding 400ms ease-in-out, background 400ms ease-in-out;

        +media( desktop )
        {
            animation: pulse 2s ease-in-out infinite;
            animation-delay: calc( var( --animation-delay ) * 0.5s );
        }
    }

    .marker-content:hover
    {
        padding: 2px;

        background: rgba( 173, 166, 144, 0.40 );

        animation: none;
    }

    .marker-content:hover .marker
    {
        padding: .25rem;
    }

    .marker
    {
        z-index: 1;

        border-radius: 100%;
        padding: 0.1rem;

        display: flex;
        gap: 8px;
        align-items: center;

        background: lightGoldColor;

        transition: padding 400ms ease-in-out;

        +media( desktop )
        {
            padding: 0.18rem;
        }
    }

    // -- MIXINS

    @keyframes pulse
    {
        0%, 100%
        {
            border: 0px solid rgba(173, 166, 144, 0);

            background: rgba(173, 166, 144, 0.00);
        }

        50%
        {
            border: .5px solid rgba(173, 166, 144, 1);

            background: rgba(173, 166, 144, 0.50);
        }
    }
</style>

<div class="marker-container" style="left: { marker.x }%; top: { marker.y }%">
    {#if isMarkerCardOpen }
        <MarkerCard isOpen={ isMarkerCardOpen } marker={ marker } markerId={ markerIndex } />
    {/if}

    <div 
        class="marker-content" 
        id={ markerIndex }
        style="--animation-delay: { markerIndex };"
        on:touchstart={ handleOpenMarkerCard }
        on:mouseenter={ handleOpenMarkerCard } 
        on:mouseleave={ handleCloseMarkerCard } 
        >
        <div 
            class="marker" 
            >
            <!-- <svg class="logo" xmlns="http://www.w3.org/2000/svg" width="12" height="12" viewBox="0 0 12 12" fill="none">
                <path d="M6.61099 0.0147095H11.5V4.90372H6.61099V0.0147095ZM6.61099 6.1257H11.5V11.0147H6.61099V6.1257ZM0.5 0.0147095H2.9445C4.29448 0.0147095 5.38901 1.10924 5.38901 2.45921C5.38901 3.80919 4.29448 4.90372 2.9445 4.90372H0.5V0.0147095ZM0.5 6.1257H2.9445C4.29448 6.1257 5.38901 7.22023 5.38901 8.57021C5.38901 9.92018 4.29448 11.0147 2.9445 11.0147H0.5V6.1257Z" fill="#1B1B1B"/>
            </svg> -->
        </div>
    </div>
</div>
