// -- IMPORTS

import { writable } from 'svelte/store';

// -- VARIABLES

export let pageStore = writable( {
    block: {
        hubBlockArray: [],
        hubArray: [],
        hub: {},
        partnerCompanyArray: []
    },
    page: {
        hubPage: {},
    }
} );
