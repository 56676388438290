<script>
    // -- IMPORTS

    import { getLocalizedText } from 'senselogic-gist';
    import PartnerCompaniesCard from './PartnerCompaniesCard.svelte';
    import { enabledAdminView } from '$lib/store/adminStore.js';
    import AdminEditButton from '../element/AdminEditButton.svelte';
    import { languageTagStore } from '$src/lib/store/languageTagStore';
    import { textStore } from '$src/lib/store/textStore';

    // -- VARIABLES

    export let partnerCompanyArray = [];
    let isPartnerBeingHovered = false

    // -- FUNCTIONS

    function isHovering(
        )
    {
        isPartnerBeingHovered = !isPartnerBeingHovered
    }
</script>

<style lang="stylus">
    // -- IMPORTS

    @import '../../../constant.styl';
    @import '../../../mixin.styl';

    // -- CLASSES

    .partner-companies-container
    {
        border-bottom: 1px solid grayColor500;
        padding-bottom: 4rem;

        display: flex;
        flex-direction: column;
        align-items: center;
        align-self: stretch;

        transition: all 2s;

        +media( desktop )
        {
            max-width: 77vw;
            padding-bottom: 8rem;
        }
    }

    .partner-companies-container-content
    {
        position: relative;

        display: flex;
        flex: 1 0 0;
        flex-direction: column;
        gap: -440px;
        align-items: center;
        align-self: stretch;

        transition: all 2s;
    }

    .partner-companies-list
    {
        width: 100%;

        display: grid;
        grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
        gap: 2rem;
        row-gap: 8rem;
        justify-items: center;

        transition: all 2s;

        +media( desktop )
        {
            grid-template-columns: repeat(4, minmax(200px, 1fr));
        }
    }

    .partner-companies-overlay
    {
        position: absolute;
        bottom: 0;
        left: 0;

        height: 25%;
        width: 100%;

        align-self: stretch;

        background: linear-gradient( 180deg, rgba(27, 27, 27, 0.00) 0%, #1B1B1B 100% );

        pointer-events: none;
        transition: all 2s;
    }

    .is-hover
    {
        height: 1%;
    }

    .and-more-text
    {
        max-width: 60rem;

        line-height: 1.5;
        font-size: 1.5rem;
        font-weight: 400;
        letter-spacing: 0.48px;
        text-align: center;
        color: lightGoldColor;

        transition: all 2s;
    }
</style>

{#if partnerCompanyArray.length > 0 }
    <div class="partner-companies-container" class:is-admin-enabled={ $enabledAdminView }>
        <AdminEditButton
            type="partner-company"
        />

        <div class="partner-companies-container-content">
            <div 
                class="partner-companies-list"
                on:mouseenter={ isHovering }
                on:mouseleave={ isHovering }
                >
                {#each partnerCompanyArray as partner }
                    <PartnerCompaniesCard
                        imagePath={ partner.imagePath }
                        text={ partner.text }
                        id={ partner.id }
                    />
                {/each}
            </div>

            <div class="partner-companies-overlay" class:is-hover={isPartnerBeingHovered}/>
        </div>

        <span class="and-more-text">
            { getLocalizedText( $textStore[ 'and-more-networking-label' ] || '' , $languageTagStore ) }
        </span>
    </div>
{/if}
