<script>
    // -- IMPORT

    import { draw } from "svelte/transition";

    // -- CONSTANTS

    export let isMobileNavigationOpen
</script>

<svg width="48" height="48" viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg">
    {#if isMobileNavigationOpen }
        <path in:draw={ { duration: 250 } } d="M17.6357 30.3633L30.3637 17.6354" stroke="#ADA690" stroke-width="2" stroke-linecap="square" stroke-linejoin="round"/>
        <path in:draw={ { duration: 250 } } d="M17.6357 17.6357L30.3637 30.3637" stroke="#ADA690" stroke-width="2" stroke-linecap="square" stroke-linejoin="round"/>
    {:else}
        <path in:draw={ { duration: 250 } } d="M15 18H33" stroke="#ADA58C" stroke-width="2" stroke-linecap="square" stroke-linejoin="round"/>
        <path in:draw={ { duration: 250 } } d="M15 24H33" stroke="#ADA58C" stroke-width="2" stroke-linecap="square" stroke-linejoin="round"/>
        <path in:draw={ { duration: 250 } } d="M15 30H33" stroke="#ADA58C" stroke-width="2" stroke-linecap="square" stroke-linejoin="round"/>
    {/if}
</svg>
