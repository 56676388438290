<script>
    // -- IMPORTS

    import { getImagePath } from '$lib/filePath';
    import Seo from '$lib/component/element/Seo.svelte';
    import { fetchData, websiteUrl } from '$lib/base.js';
    import { enabledAdminView } from '$lib/store/adminStore.js';
    import { getLocalizedText } from 'senselogic-gist';
    import { languageTagStore } from '$lib/store/languageTagStore';
    import { textStore } from '$lib/store/textStore';
    import AdminEditButton from '$lib/component/element/AdminEditButton.svelte';
    import AnimatedContainer from '$lib/component/element/AnimatedContainer.svelte';
    import AnimatedText from '$lib/component/element/AnimatedText.svelte';
    import PartnerCompanies from '$lib/component/Networking/PartnerCompanies.svelte';
    import Map from '$lib/component/Networking/Map.svelte';
    import { pageStore } from '../store/pageDataStore';
    import { onMount } from 'svelte';
    import { languageArrayStore } from '../store/languageArrayStore';
    import FullLoading from '../component/Layout/FullLoading.svelte';

    // -- VARIABLES

    let isLoading = true;
    let name = '';
    let slug = '';
    let metaTitle = '';
    let metaDescription = '';
    let metaKeywords = '';
    let imagePath = '';
    let url = '';

    let networkingPage = $pageStore.page.networkingPage;
    let networkingBlockArray = $pageStore.block.networkingBlockArray;
    let networkingBlockArrayBySlug = $pageStore.block.networkingBlockArrayBySlug;
    let networkCityArray = $pageStore.block.networkCityArray;
    let networkingBenefitsArray = $pageStore.block.networkingBenefitsArray;
    let partnerCompanyArray = $pageStore.block.partnerCompanyArray;

    async function loadData(
        )
    {
        try
        {
            isLoading = true

            let pageData =
                await fetchData(
                    '/api/page/networking',
                    {
                        method: 'POST',
                        body: JSON.stringify(
                            {
                            },
                        ),
                        headers: { 'Content-Type': 'application/json' }
                    }
                    );

            let networkBenefits = pageData.blockArray.filter(( block ) => block.typeSlug === 'text-and-image');

            $pageStore.page.networkingPage = pageData.page;
            $pageStore.block.networkingBlockArray = pageData.blockArray;
            $pageStore.block.networkingBlockArrayBySlug = pageData.networkingBlockArrayBySlug;
            $pageStore.block.networkCityArray = pageData.networkCityArray;
            $pageStore.block.partnerCompanyArray = pageData.partnerCompanyArray;
            $pageStore.block.networkBenefitsArray = networkBenefits;

            networkingPage = pageData.page;
            networkingBlockArray = pageData.blockArray;
            networkingBlockArrayBySlug = pageData.networkingBlockArrayBySlug;
            networkCityArray = pageData.networkCityArray;
            partnerCompanyArray = pageData.partnerCompanyArray;
            networkingBenefitsArray = networkBenefits;

            metaTitle = pageData.page.metaTitle ?? pageData.page.title;
            metaDescription = pageData.page.metaDescription ?? pageData.page.subTitle;
            metaKeywords = pageData.page.metaKeywords;
            imagePath = pageData.page.imagePath;
            url = websiteUrl;
        }
        catch ( error )
        {
            console.error( 'Error:', error )
        }
        finally
        {
            isLoading = false;
        }
    }

    onMount(
        () =>
        {
            if
            (
                networkingPage === undefined
                || networkingBlockArray === undefined
                || networkingBlockArrayBySlug === undefined
                || networkCityArray === undefined
                || networkingBenefitsArray === undefined
                || partnerCompanyArray === undefined
            )
            {
                loadData();
            }
            else
            {
                isLoading = false;
            }
        }
        );

    $: if ( networkingPage !== undefined && !isLoading )
        {
            name = networkingPage.slug;
            slug = networkingPage.slug;
            metaTitle = networkingPage.metaTitle;
            metaDescription = networkingPage.metaDescription;
            metaKeywords = networkingPage.metaKeywords;
            imagePath = networkingPage.imagePath;
            url = websiteUrl;
        }
</script>

<style lang="stylus">
    // -- IMPORTS

    @import '../../constant.styl';
    @import '../../mixin.styl';

    // -- CLASSES

    :global( html > body > div > main )
    {
        background: unset;
        background-color: darkGreyColor;
    }

    .networking-section
    {
        padding: 5rem 1.5rem;

        gap: 5rem;

        +media( desktop )
        {
            max-width: 77vw;
        }
    }

    .networking-heading
    {
        display: flex;
        flex-direction: column;
        gap: 2rem;
        align-items: center;
        align-self: stretch;
    }

    .networking-title
    {
        line-height: 2.5rem;
        font-size: 2rem;
        letter-spacing: 0.16rem;
        text-transform: uppercase;

        +media( desktop )
        {
            line-height: 7.5rem;
            font-size: 6rem;
            font-weight: 250;
            letter-spacing: 0.48rem;
            text-align: center;
        }
    }

    .what-we-offer-container
    {
    }

    @keyframes infinite-scroll
    {
        to
        {
            transform: translate( calc( -50% - 0.5rem ) );
        }
    }

    .what-we-offer-container
    {
        display: flex;
        flex-direction: column;
        gap: 2rem;
        align-items: center;
        align-self: stretch;

        +media( desktop )
        {
            gap: 5rem;
        }
    }

    .what-we-offer-card-list
    {
        display: flex;
        flex-direction: column;
        gap: 4rem;
        align-items: center;
        align-self: stretch;

        +media( desktop )
        {
            gap: 4rem;
        }
    }

    .what-we-offer-card-item
    {
        display: flex;
        flex-direction: column;
        gap: 2rem;
        align-items: flex-start;
        align-self: stretch;

        +media( desktop )
        {
            flex-direction: row;
            gap: 5rem;
        }
    }

    .what-we-offer-card-item.reverse
    {
        +media( desktop )
        {
            flex-direction: row-reverse;
        }
    }

    .what-we-offer-card-item-content
    {
        display: flex;
        flex-direction: column;
        gap: 1rem;
        align-items: flex-start;
        align-self: stretch;

        +media( desktop )
        {
            display: flex;
            flex: 1 0 0;
            gap: 2rem;
            justify-content: center;
        }
    }

    .what-we-offer-card-item-title
    {
        line-height: 2rem;
        font-size: 1.4375rem;
        font-weight: 500;
        letter-spacing: 0.08625rem;
        text-transform: uppercase;

        +media( desktop )
        {
            line-height: 2.5rem;
            font-size: 2rem;
            letter-spacing: 0.16rem;
        }
    }

    .what-we-offer-image
    {
        height: 18.75rem;
        aspect-ratio: 1;

        align-self: stretch;
        object-fit: cover;

        +media( desktop )
        {
            height: 40rem;
            width: 44.375rem;
            aspect-ratio: 3 / 4;
        }
    }

    .description-text
    {
        line-height: 1.5;
        font-size: 1.5rem;
        letter-spacing: 0.48px;
    }
</style>

<Seo
    metaTitle={ metaTitle }
    metaDescription={ metaDescription }
    metaKeywords={ metaKeywords }
    url={ url }
    imagePath={ imagePath }
    languageData={ $languageArrayStore }
    path=""
/>

{#if isLoading }
    <FullLoading />
{:else}
    <section class="networking-section">
        <div class="networking-heading" class:is-admin-enabled={ $enabledAdminView }>
            <AdminEditButton
                type="networking"
            />
            {#if networkingPage.title }
                <p class="networking-title color-light-gold">
                    <AnimatedText text={ getLocalizedText( networkingPage.title, $languageTagStore ) }/>
                </p>
            {/if}

            {#if networkingPage.subTitle }
                <p class="description-text description-text-align-center-desktop color-light-gray" style="display: none;">
                    <AnimatedText text={ getLocalizedText( networkingPage.subTitle, $languageTagStore ) }/>
                </p>
            {/if}
        </div>

        <Map
            networkingArray={ networkCityArray }
        />

        {#if partnerCompanyArray.length > 0 }
            <PartnerCompanies
                partnerCompanyArray={ partnerCompanyArray }
            />
        {/if}
    </section>
{/if}
