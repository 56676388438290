<style lang="stylus">
    // -- IMPORTS

    @import '../../../constant.styl';
    @import '../../../mixin.styl';

    // -- ELEMENTS

    // -- CLASSES

    .accordion-list
    {
        width: 100%;
        padding: 0.625rem;

        display: flex;
        flex-direction: column;
        gap: 0.12rem;
        align-items: flex-start;

        background: #000;
    }
</style>

<div class="accordion-list">
    <slot/>
</div>
