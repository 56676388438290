<script>
    // -- IMPORTS

    import { onMount, createEventDispatcher } from 'svelte';
    import Siema from 'siema';

    // -- VARIABLES

    export let perPage = 1;
    export let loop = true;
    export let autoplay = 0;
    export let duration = 200;
    export let easing = 'ease-out';
    export let startIndex = 0;
    export let draggable = true;
    export let multipleDrag = true;
    export let dots = false;
    export let controls = false;
    export let hasCounter = true;
    export let totalItemsLength = 1;
    export let threshold = 10;
    export let rtl = false;

    let currentIndex = startIndex;
    let siema;
    let controller;
    let timer;

    $: pips = controller ? controller.innerElements : []
    $: currentPerPage = controller ? controller.perPage : perPage
    $: totalDots = controller ? Math.ceil( controller.innerElements.length / currentPerPage ) : []

    // -- FUNCTIONS

    let dispatch = createEventDispatcher();

    // ~~

    export function isDotActive(
        currentIndex,
        dotIndex
        )
    {
        if ( currentIndex < 0 )
        {
            currentIndex = pips.length + currentIndex;
        }

        return currentIndex >= dotIndex * currentPerPage && currentIndex < ( dotIndex * currentPerPage ) + currentPerPage;
    }

    // ~~

    export function left(
        )
    {
        controller.prev( 1 );
    }

    // ~~

    export function right (
        )
    {
        controller.next( 1 );
    }

    // ~~

    export function go(
        index
        )
    {
        controller.goTo( index );
    }

    // ~~

    export function pause(
        )
    {
        clearInterval( timer );
    }

    // ~~

    export function resume(
        )
    {
        if ( autoplay )
        {
            timer = setInterval( right, autoplay );
        }
    }

    // ~~

    function handleChange (
        )
    {
        currentIndex = controller.currentSlide;

        dispatch(
            'change',
            {
                currentSlide: controller.currentSlide,
                slideCount: controller.innerElements.length
            }
            )
    }

    // ~~

    function resetInterval(
        node,
        condition
        )
    {
        function handleReset(
            )
        {
            pause();
            resume();
        }

        if ( condition )
        {
            node.addEventListener( 'click', handleReset );
        }

        return (
            {
                destroy()
                {
                    node.removeEventListener( 'click', handleReset );
                }
            }
            );
    }

    // -- STATEMENTS

    onMount(
        () =>
        {
            controller =
                new Siema(
                    {
                        selector: siema,
                        perPage: typeof perPage === 'object' ? perPage : Number( perPage ),
                        loop,
                        duration,
                        easing,
                        startIndex,
                        draggable,
                        multipleDrag,
                        threshold,
                        rtl,
                        onChange: handleChange
                    }
                    );

            if ( autoplay )
            {
                timer = setInterval( right, autoplay );
            }

            return (
                () =>
                {
                    autoplay && clearInterval( timer )
                    controller.destroy()
                }
                );
        }
        );

</script>

<style lang="stylus">
    // -- IMPORTS

    @import '../../../constant.styl';
    @import '../../../mixin.styl';

    // -- CLASSES

    .carousel
    {
        margin: auto;
        margin-top: 2rem;
        width: 100%;
        max-width: 85vw;

        +media( desktop )
        {
            margin-top: 5rem;
        }
    }

    .carousel-slides
    {
        height: 100%;
    }

    .carousel-controls
    {
        z-index: 3;
        position: absolute;
        bottom: 1rem;
        right: 1rem;
    }

    .carousel-counter
    {
        border-radius: 0.75rem;
        padding: 0.5rem 1rem;

        background-color: rgba( 23, 23, 23, 0.60 );
        box-shadow: 0px 0px 8px 0px rgba( 23, 23, 23, 0.04 );
    }

    // ELEMENTS

    ul
    {
        position: absolute;

        margin-top: -2rem;
        width: 100%;
        padding: 0;

        display: flex;
        justify-content: center;

        list-style-type: none;
    }

    ul button
    {
        margin: 0.5rem;
        height: 0.5rem;
        width: 0.5rem;
        border-radius: 100%;

        background-color: rgba( 255, 255, 255, 0.5 );
    }

    ul button:hover
    {
        +media( desktop )
        {
            background-color: rgba( 255, 255, 255, 0.85 );
        }
    }

    ul button.active
    {
        background-color: rgba( 255, 255, 255, 1 );
    }
</style>

<div class="carousel">
    <div class="carousel-slides" bind:this={ siema } >
        <slot />
    </div>
    {#if totalItemsLength > 1 }
        <div class="carousel-controls">
            <div class="carousel-controls-container">
                {#if controls }
                    <button class="left" on:click={ left } use:resetInterval={ autoplay } aria-label="left">
                        <slot name="carousel-left-control"></slot>
                    </button>
                {/if}
                {#if hasCounter && totalItemsLength >= 2 }
                    <div class="font-size-75 font-weight-700 color-white carousel-counter">
                        <slot name="carousel-counter-control">{ currentIndex + 1 } / { totalItemsLength }</slot>
                    </div>
                {/if}
                {#if controls }
                    <button class="right" on:click={ right } use:resetInterval={ autoplay } aria-label="right">
                        <slot name="carousel-right-control"></slot>
                    </button>
                {/if}
            </div>
        </div>
    {/if}
    {#if dots }
        <ul>
            {#each { length: totalDots } as index }
                <button on:click={ () => go( index * currentPerPage ) } class={ isDotActive( currentIndex, index ) ? "active" : "" }></button>
            {/each}
        </ul>
    {/if}
</div>
