<script>
    import { onMount } from 'svelte';
    import { fetchData } from '../base';
    import Seo from '../component/element/Seo.svelte';
    import FoundersSection from '../component/homePage/FoundersSection.svelte';
    import ManagerSection from '../component/homePage/ManagerSection.svelte';
    import { languageArrayStore } from '../store/languageArrayStore';
    import { founderArrayStore, managerArrayStore } from '../store/memberStore';
    import urlStore from '../store/urlStore';
    import FullLoading from '../component/Layout/FullLoading.svelte';

    // -- VARIABLES

    let isLoading = true;
    let managerArray = $managerArrayStore;
    let founderArray = $founderArrayStore

    // -- FUNCTIONS

    async function loadData(
        )
    {
        try
        {
            isLoading = true

            let pageData =
                await fetchData(
                    '/api/get-team',
                    {
                        method: 'POST',
                        body: JSON.stringify(
                            {
                            },
                        ),
                        headers: { 'Content-Type': 'application/json' }
                    }
                    );

            managerArrayStore.set( pageData.managerArray );
            founderArrayStore.set( pageData.founderArray );

            managerArray =  pageData.managerArray
            founderArray =  pageData.founderArray
        }
        catch ( error )
        {
            console.error( 'Error:', error )
        }
        finally
        {
            isLoading = false;
        }
    }

    // -- STATEMENTS

    onMount(
        () =>
        {
            if
            (
                managerArray === null
                || founderArray === null
            )
            {
                loadData();
            }
            else
            {
                isLoading = false;
            }
        }
        );

    // ~~

    $: if ( $urlStore.pathname.includes( 'team' ) )
        {
            document.title = 'Team';
        }
</script>

<style lang="stylus">
    // -- IMPORTS

    @import '../../constant.styl';
    @import '../../mixin.styl';

    // -- CLASSES

    .home-image-container
    {
        height: 32.5rem;
        width: 100%;

        +media( smaller-32em )
        {
            background-position: center right 30% !important;
        }
    }
</style>

<Seo
    metaTitle={ "Bamhub - Team" }
    metaDescription={ "Get to know a little more about our team and our ambassadors" }
    url={ 'https://bamhub.com' }
    languageData={ $languageArrayStore }
    path="team"
/>

{#if isLoading }
    <FullLoading />
{:else}
    <FoundersSection founderArray={ founderArray } />

    <ManagerSection managerArray={ managerArray } />
{/if}
