<script>
    // -- IMPORTS

    import { onMount } from 'svelte';
    import { fetchData } from '../base';
    import { websiteUrl, isObjectEmpty } from '$lib/base.js';
    import { getImagePath } from '$lib/filePath';
    import { enabledAdminView } from '$lib/store/adminStore.js';
    import { getLocalizedText } from 'senselogic-gist';
    import { languageTagStore } from '$lib/store/languageTagStore';
    import { textStore } from '$lib/store/textStore';
    import PageHeading from '$lib/component/header/PageHeading.svelte';
    import { languageArrayStore } from '../store/languageArrayStore';
    import { pageStore } from '../store/pageDataStore';
    import Seo from '$lib/component/element/Seo.svelte';
    import AdminEditButton from '$lib/component/element/AdminEditButton.svelte';
    import AnimatedContainer from '$lib/component/element/AnimatedContainer.svelte';
    import AnimatedText from '$lib/component/element/AnimatedText.svelte';
    import FullLoading from '../component/Layout/FullLoading.svelte';
    import urlStore from '../store/urlStore';

    // -- VARIABLES

    let isLoading = true;
    let name = '';
    let metaTitle = '';
    let metaDescription = 'Transforming Connections into Innovative Collaborations';
    let metaKeywords = '';
    let imagePath = '';
    let url = '';

    let digitalizationPage = $pageStore.page.digitalizationPage || {};
    let digitalizationBlockArray = $pageStore.block.digitalizationSection;

    // -- FUNCTIONS

    async function loadData(
        )
    {
        try
        {
            isLoading = true

            let [
                    digitalizationPageData,
                    digitalizationBlockArrayData
                ] =
                await Promise.all(
                [
                    fetchData(
                        '/api/page/get-by-route',
                        {
                            method: 'POST',
                            body: JSON.stringify(
                                {
                                    route: '/digitalization'
                                }
                            ),
                            headers: { 'Content-Type': 'application/json' }
                        }
                    ),
                    fetchData(
                        '/api/block/get-by-page-id',
                        {
                            method: 'POST',
                            body: JSON.stringify(
                                {
                                    pageId: 'zvDIiGoCTKNbgUzju2NmmA'
                                }
                            ),
                            headers: { 'Content-Type': 'application/json' }
                        }
                    )
                ]
            )

            $pageStore.page.digitalizationPage = digitalizationPageData.page;
            $pageStore.block.digitalizationSection = digitalizationBlockArrayData.blockArray;
            digitalizationBlockArray = digitalizationBlockArrayData.blockArray;
            digitalizationPage = digitalizationPageData.page;
            name = digitalizationPageData.page.slug;
            metaTitle = digitalizationPageData.page.metaTitle ?? digitalizationPageData.page.title;
            metaDescription = digitalizationPageData.page.metaDescription ?? digitalizationPageData.page.subTitle;
            metaKeywords = digitalizationPageData.page.metaKeywords;
            imagePath = digitalizationPageData.page.imagePath;
            url = websiteUrl;
        }
        catch ( error )
        {
            console.error( 'Error:', error )
        }
        finally
        {
            isLoading = false;
        }
    }

    // -- STATEMENTS

    onMount(
        () =>
        {
            if( digitalizationPage === undefined || digitalizationBlockArray === undefined ) {
                loadData()
                digitalizationPage.imagePath = '/image/digitalization/heading.avif';
            }
            else
            {
                isLoading = false;
                digitalizationPage.imagePath = '/image/digitalization/heading.avif';
            }
        }
    )

    $: if ( $urlStore.pathname.includes( 'digitalization' ) )
        {
            document.title = 'Digitalization';
        }
</script>

<style lang="stylus">
    // -- IMPORTS

    @import '../../constant.styl';
    @import '../../mixin.styl';

     // -- CLASSES

     .digitalization-section
     {
        padding: 5rem 1.5rem;

        gap: 4rem;

        +media( desktop )
        {
            max-width: 77vw;
            padding: 5rem 0rem 7.5rem 0rem;
        }
     }

    .digitalization-heading-image
    {
        height: 27.5rem;
        width: 100%;

        +media( desktop )
        {
            height: 37.5rem;
        }
    }

    .digitalization-section-card-list.right
    {
        +media( desktop )
        {
            flex-direction: row-reverse;
        }
    }

    .digitalization-section-card-list
    {
        width: 100%;

        display: flex;
        flex-direction: column;
        gap: 2rem;
        align-items: flex-start;

        +media( desktop )
        {
            flex-direction: row;
            gap: 5rem;
        }
    }

    .digitalization-section-card-image
    {
        height: 18.75rem;
        width: 100%;

        object-fit: cover;
        object-position: center 50%;

        +media( desktop )
        {
            height: 40rem;
            max-width: 50%;

            flex: 1;
        }
    }

    .digitalization-section-card-content
    {
        display: flex;
        flex: 1;
        flex-direction: column;
        gap: 2rem;
        justify-content: center;
        align-items: flex-start;
        align-self: stretch;
    }

    .digitalization-section-card-title
    {
        text-transform: uppercase;
    }
</style>

<Seo
    metaTitle={ metaTitle }
    metaDescription={ metaDescription }
    metaKeywords={ metaKeywords }
    url={ url }
    imagePath={ imagePath }
    languageData={ $languageArrayStore }
    path=''
/>

<svelte:head>
    {#if !isObjectEmpty( name ) }
        <title>{ getLocalizedText( name ) }</title>
    {/if}
    {#if digitalizationPage.imagePath }
        <link rel="preload" href={ getImagePath( digitalizationPage.imagePath ) } as="image">
    {/if}
</svelte:head>

{#if isLoading }
    <FullLoading />
{:else}
    {#if digitalizationPage.title }
        <PageHeading
            title={ digitalizationPage.title }
        />
    {/if}

    {#if digitalizationPage.imagePath }
        <div
            class="digitalization-heading-image"
            aria-hidden="true"
            style="
                    background: URL( {getImagePath( digitalizationPage.imagePath, '1920' )} ) no-repeat center center / cover,
                                URL( {getImagePath( digitalizationPage.imagePath, '360' )} ) no-repeat center center / cover;
                    object-fit: { digitalizationPage.imageFit };
                    object-position: { digitalizationPage.imageHorizontalPosition } { digitalizationPage.imageVerticalPosition };
                "
        />
    {/if}

    <AdminEditButton
        hash="digitalization"
        inset="10rem 5rem auto auto"
    />

    <section  class="digitalization-section">

        <span class="description-text description-text-align-center-desktop color-light-grey" class:is-admin-enabled={ $enabledAdminView }>
            <AdminEditButton
                type="text"
                slug="digitalization-subtitle"
            />
            <AnimatedText text={ getLocalizedText( $textStore[ 'digitalization-subtitle' ] || '', $languageTagStore ) } />
        </span>

        {#each digitalizationBlockArray as { id, title, text, imagePath, imageSide } }
            <AnimatedContainer style="width: 100%;">
                <div class="digitalization-section-card-list { imageSide }" class:is-admin-enabled={ $enabledAdminView }>
                    <AdminEditButton
                        type="block"
                        id={ id }
                    />
                    {#if imagePath }
                        <div
                            class="digitalization-section-card-image"
                            style="
                                background: URL( { getImagePath( imagePath, '1920' ) } ) no-repeat center center / cover,
                                            URL( { getImagePath( imagePath, '360' ) } ) no-repeat center center / cover;
                                "
                        />
                    {/if}

                    <div class="digitalization-section-card-content">
                        <span class="digitalization-section-card-title font-size-150 font-weight-500 color-light-gold">{ getLocalizedText( title || '', $languageTagStore ) }</span>

                        <span class="description-text color-light-gray">{ getLocalizedText( text || '', $languageTagStore ) }</span>
                    </div>
                </div>
            </AnimatedContainer>
        {/each}
    </section>
{/if}
