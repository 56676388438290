<script>

    // -- IMPORTS

    import { fly } from 'svelte/transition';
    import { onDestroy, onMount } from 'svelte';
    import { toastEmitter } from '$lib/toast';
    import ProgressBar from './ProgressBar.svelte';
    import Loading from '$lib/component/element/Loading.svelte';

    // -- VARIABLES

    export let message;
    export let onRemove;
    let timerId;

    // -- STATEMENTS

    onMount(
        () =>
        {
            timerId = setTimeout(
                        () =>
                        {
                            onRemove( message.id );
                        },
                        3000
                        );

            return (
                () =>
                {
                    clearTimeout( timerId );
                }
                );
        }
        );
</script>

<style lang="stylus">
    // -- IMPORTS

    @import '../../../constant.styl';
    @import '../../../mixin.styl';

    // -- CLASSES

    .toast
    {
        position: relative;

        overflow: hidden;
        width: 313px;
        border-radius: 4px;
        padding: 20px 12px;

        display: flex;
        align-items: center;

        background: grayColor500;

        color: lightGreyColor;
    }

    .toast p
    {
        font-size: 14px;
        text-align: left;
    }

    .toast button
    {
        position: absolute;
        top: 0.5rem;
        right: 0.5rem;

        overflow: hidden;
        outline: 0;
        border: 0;

        background: transparent;

        color: lightGreyColor;

        cursor: pointer
    }
</style>

<div class="toast" transition:fly>
    {#if message.variant === 'progress' }
        <ProgressBar
            closeToast={ () => onRemove( message.id ) }
            hide={ message.variant !== 'progress' }
            progress={ message.progress ?? 0 }
        />
        <Loading/>
    {/if}
    {#if message.icon }
        <div class="{ message.variant }-icon size-150 margin-right-50" alt=""/>
    {/if}
    <p>{ message.text }</p>
    <button on:click={ () => onRemove( message.id ) }>
        <svg
            width="12"
            height="12"
            viewBox="0 0 12 12"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                d="M1 1L6 6M11 11L6 6M6 6L11 1L1 11"
                stroke="#B3B3B3"
                stroke-width="2"
            />
        </svg>
    </button>
</div>
