<script>
    // -- IMPORTS

    import { navigate } from 'svelte-routing';
    import Button  from '$lib/component/element/Button.svelte';
    import { user } from '$lib/store/userStore';
    import { fetchData } from '../base';
  import urlStore from '../store/urlStore';

    // -- VARIABLES

    let email;
    let password;
    let message;
    let messageType;

    // -- FUNCTIONS

    async function handleSignIn(
        event
        )
    {
        event.preventDefault();
        let formData = new FormData( event.target );

        let response =
            await fetchData(
                '/api/sign-in',
                {
                    method: "POST",
                    credentials: 'include',
                    body: formData
                }
                );

        if ( response.error )
        {
            messageType = 'error';
            message = response.error;
        }
        else
        {
            $user = response.user;
            window.location.replace(`${ window.location.origin }/admin`)
        }
    };

    async function handleSignOut(
        event
        )
    {
        event.preventDefault();

        message = null;
        messageType = null;

        await fetchData(
            '/api/sign-out',
            {
                method: 'POST',
                credentials: 'include'
            }
        );

        $user = null;

        return () =>
        {
            navigate( window.origin + '/en' );
        }
    }

    $: if ( $urlStore.pathname.includes( 'login' ) )
    {
        document.title = 'Login';
    }
</script>

<style lang="stylus">
    // -- IMPORTS

    @import '../../constant.styl';

    // -- CLASSES

    .login-form-container
    {
        margin: 10rem auto;
        width: 80vw;
    }

    .error
    {
        color: red;
    }

    .login-form
    {
        border: 1px solid lightGoldColor;
        padding: 2rem;

        display: flex;
        flex-direction: column;
        gap: 1.5rem;
    }

    .login-form-input-group
    {
        display: flex;
        flex-direction: column
    }

    input
    {
        border: 1px solid lightGoldColor;

        background: darkGreyColor;

        color: lightGreyColor;
    }
</style>

<div class="login-form-container">
    {#if !$user }
    <h3>LOGIN</h3>
    <form
        class="login-form"
        on:submit={ handleSignIn}
    >
        {#if message }
            <p class="login-form-message { messageType }">
                { message }
            </p>
        {/if}
        <div class="login-form-input-group">
            <label for="email">Email</label>
            <input name="email" type="email" autocomplete="email" bind:value={ email } />
            <label for="password">Password</label>
            <input name="password" type="password" autocomplete="current-password" bind:value={ password } />
        </div>
        <Button type="submit">
            LOG IN
        </Button>
    </form>
    {:else}
        <h3>You're already logged in</h3>
        <form
            class="login-form"
            on:submit={ handleSignOut }
        >
            <Button type="submit">
                SIGN OUT
            </Button>
        </form>
    {/if}
</div>
