<script>
    // -- VARIABLES

    export let type = 'button';
    export let color = 'transparent';
    export let href = null;
</script>

<style lang="stylus">
    // -- IMPORTS

    @import '../../../constant.styl';
    @import '../../../mixin.styl';

    // -- CLASSES

    .adornment-button
    {
        align-items: center;
        appearance: none;
        position: relative;

        overflow: visible;
        box-sizing: border-box;
        margin: 0px -12px 0px 0px;
        outline: 0px;
        border: 0px;
        border-radius: 50%;
        padding: 8px;

        display: inline-flex;
        flex: 0 0 auto;
        justify-content: center;

        background-color: var( --color );

        font-size: 1.5rem;
        vertical-align: middle;
        text-align: center;
        text-decoration: none;
        color: grayColorTransparent70;

        user-select: none;
        cursor: pointer;
        transition: background-color 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
    }

    .adornment-button[data-color]
    {
        background-color: attr(data-color);
    }

    .adornment-button:hover
    {
        background-color: rgba( 255, 255, 255, 0.15 );
    }
</style>

{#if href }
    <a href={ href } class="adornment-button" style="--color: { color }" { type } on:click>
        <slot/>
    </a>
{:else}
    <button class="adornment-button" style="--color: { color }" { type } on:click>
        <slot/>
    </button>
{/if}
